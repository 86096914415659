/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

/* MODAL */

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(222 76 0 / 0.7);
    backdrop-filter: blur(3px) saturate(0%);
    display: flex;
    align-items:flex-start;
    justify-content:flex-end;
    z-index: 4;
}

/* MODAL BOX */

    .modal-window {
        margin: 1rem;
        color: var(--red-yoyo);
    }

        /* BOXES */

        .one-col-modal {
            background-color: var(--white-yoyo);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 24vw;
        }

        .two-col-modal {
            background-color: var(--white-yoyo);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 49vw;
        }

            .modal-title {
                all: unset;
            }

        /* FORMS */

        #flex-form {
            display: flex;
            flex-direction: column;
            gap: 1rem;             
        }

            .row-one-flex-form {
                display: flex;
                gap: 1rem;
            }

            .row-two-flex-form {
                display: flex;
                gap: 1rem;
            }

        #grid-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 1rem;
        }

            .col-1-grid-form {
                grid-column: 1 / span 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .col-2-grid-form {
                grid-column: 2 / span 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            #grid-form .button {
                grid-column: 1 / span 2;
            }

        /* GENERAL STYLES FOR FORMS */

            .file-selector {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
            }

                .file-selector figure {
                    width: 100%;
                }

                    .file-selector figure img {
                        width: 100%;
                    }

            .modal-form input {
                all: unset;
                border: solid thin var(--red-yoyo);
                padding: 0.75rem 1rem;
            }

            .modal-form textarea {
                all: unset;
                overflow: auto;
                border: solid thin var(--red-yoyo);
                padding: 0.75rem 1rem;
                resize: vertical;
            }

                .modal-form input#email-form, .modal-form input#inserted-music-form {
                    text-transform: lowercase;
                }

                .modal-form input#art-cover-form {
                    all: unset;
                }

            .file-input-label {
                padding: 0.75rem 1rem;
                background-color: var(--red-yoyo);
                border: solid thin var(--red-yoyo);
                color: var(--white-yoyo);
                cursor: pointer;
            }
            
                .file-input-label input[type="file"] {
                    display: none !important;
                }

}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

/* MODAL */

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(222 76 0 / 0.7);
    backdrop-filter: blur(3px) saturate(0%);
    display: flex;
    align-items:flex-start;
    justify-content:flex-end;
    z-index: 4;
}

/* MODAL BOX */

    .modal-window {
        margin: 1rem;
        color: var(--red-yoyo);
    }

        /* BOXES */

        .one-col-modal {
            background-color: var(--white-yoyo);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 24vw;
        }

        .two-col-modal {
            background-color: var(--white-yoyo);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 49vw;
        }

            .modal-title {
                all: unset;
            }

        /* FORMS */

        #flex-form {
            display: flex;
            flex-direction: column;
            gap: 1rem;             
        }

            .row-one-flex-form {
                display: flex;
                gap: 1rem;
            }

            .row-two-flex-form {
                display: flex;
                gap: 1rem;
            }

        #grid-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 1rem;
        }

            .col-1-grid-form {
                grid-column: 1 / span 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .col-2-grid-form {
                grid-column: 2 / span 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            #grid-form .button {
                grid-column: 1 / span 2;
            }

        /* GENERAL STYLES FOR FORMS */

            .file-selector {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
            }

                .file-selector figure {
                    width: 100%;
                }

                    .file-selector figure img {
                        width: 100%;
                    }

            .modal-form input {
                all: unset;
                border: solid thin var(--red-yoyo);
                padding: 0.75rem 1rem;
            }

            .modal-form textarea {
                all: unset;
                overflow: auto;
                border: solid thin var(--red-yoyo);
                padding: 0.75rem 1rem;
                resize: vertical;
            }

                .modal-form input#email-form, .modal-form input#inserted-music-form {
                    text-transform: lowercase;
                }

                .modal-form input#art-cover-form {
                    all: unset;
                }

            .file-input-label {
                padding: 0.75rem 1rem;
                background-color: var(--red-yoyo);
                border: solid thin var(--red-yoyo);
                color: var(--white-yoyo);
                cursor: pointer;
            }
            
                .file-input-label input[type="file"] {
                    display: none !important;
                }

}
.contact-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

    #with-link {
        cursor: pointer;
    }

    #is-lower-case {
        text-transform: lowercase;
    }
/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .project-page {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 100vh 50vh;
        grid-template-areas: 
        
            "presentation single-project single-project single-project"
            "footer footer footer footer"

        ;
        column-gap: 1rem;
    }

        .project-page .presentation {
            grid-area: presentation ;
            padding: 1rem 0 1rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .project-page .single-project {
            grid-area: single-project;
            padding: 1rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto 1fr auto auto;
            grid-template-areas:
                "header header artcover"
                "desc uiproj insert"
                "genre genre genre"
            ;
            gap: 1rem;
            align-content: stretch;
            padding: 1rem 1rem 0 0;
        }

        .project-page .footer {
            grid-area: footer;
            padding: 1rem;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
        }

}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

    .project-page {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 100vh 50vh;
        grid-template-areas: 
        
            "presentation single-project single-project single-project"
            "footer footer footer footer"

        ;
        column-gap: 1rem;
    }

        .project-page .presentation {
            grid-area: presentation ;
            padding: 1rem 0 1rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .project-page .single-project {
            grid-area: single-project;
            padding: 1rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto 1fr auto auto;
            grid-template-areas:
                "header header artcover"
                "desc uiproj insert"
                "genre genre genre"
            ;
            gap: 1rem;
            align-content: stretch;
            padding: 1rem 1rem 0 0;
        }

        .project-page .footer {
            grid-area: footer;
            padding: 1rem;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
        }

}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .project-page {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 75vh 25vh;
        grid-template-areas: 
        
            "presentation single-project single-project"
            "footer footer footer"

        ;
        column-gap: 0.5rem;
    }

        .project-page .presentation {
            grid-area: presentation ;
            padding: 0.5rem 0 0.5rem 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .project-page .single-project {
            grid-area: single-project;
            padding: 0.5rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 1fr auto auto auto;
            grid-template-areas:
                "header artcover"
                ". desc"
                "insert insert"
                "genre genre"
            ;
            gap: 0.5rem;
            align-content: stretch;
            padding: 0.5rem 0.5rem 0 0;
        }

        .project-page .footer {
            grid-area: footer;
            padding: 0.5rem;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 0.5rem;
        }

}

/* MOBILE APPLICATION */

@media (max-width: 728px) {

    .project-page {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

        .project-page .presentation {
            display: none;
        }

        .project-page .single-project {
            padding: 1rem 0 0 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                "header"
                "desc"
                "insert"
                "genre"
                "artcover"
                "exit"
            ;
            gap: 1rem;
        }

        .project-page .footer {
            grid-area: footer;
            padding: 1rem;
            background-color: var(--red-yoyo);
        }

            .project-page .footer .about {
                display: none;
            }
}
/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {
    .App {
        font-size: 1.5rem;
    }
}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {
    .App {
        font-size: 1.4rem;
    }
}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {
    .App {
        font-size: 1rem;
    }
}

/* MOBILE APPLICATION */

@media (max-width: 728px) {
    .App {
        font-size: 1.3rem;
    }
}
/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .home-page {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 100vh 50vh;
        grid-template-areas: 
        
            "presentation projects projects projects"
            "footer footer footer footer"

        ;
        column-gap: 1rem;
    }

        .home-page .presentation {
            grid-area: presentation ;
            padding: 1rem 0 1rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .home-page .projects {
            grid-area: projects;
            padding: 1rem 0;
            overflow: hidden;
        }

        .home-page .footer {
            grid-area: footer;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
            width: 100%;
            padding: 1rem;
        }

}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

    .home-page {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 100vh 50vh;
        grid-template-areas: 
        
            "presentation projects projects projects"
            "footer footer footer footer"

        ;
        column-gap: 1rem;
    }

        .home-page .presentation {
            grid-area: presentation ;
            padding: 1rem 0 1rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .home-page .projects {
            grid-area: projects;
            padding: 1rem 0;
            overflow: hidden;
        }

        .home-page .footer {
            grid-area: footer;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
            width: 100%;
            padding: 1rem;
        }

}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .home-page {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 75vh 25vh;
        grid-template-areas: 
        
            "presentation projects projects"
            "footer footer footer"

        ;
        column-gap: 0.5rem;
    }

        .home-page .presentation {
            grid-area: presentation ;
            padding: 0.5rem 0 0.5rem 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .home-page .projects {
            grid-area: projects;
            padding: 0.5rem 0.5rem 0.5rem 0;
            overflow: hidden;
        }

        .home-page .footer {
            grid-area: footer;
            background-color: var(--red-yoyo);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 0.5rem;
            width: 100%;
            padding: 0.5rem;
        }

}

/* MOBILE APPLICATION */

@media (max-width: 728px) {

    .home-page {
        display: flex;
        flex-direction: column;
    }

        .home-page .presentation {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem;
            gap: 1rem;
        }

        .home-page .projects {
        }

        .home-page .footer {
            background-color: var(--red-yoyo);
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;
        }

}
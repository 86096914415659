/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .about {
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
            .slider-photos-carousel {
                width: 50%;
                filter: grayscale(100%);
                mix-blend-mode: screen;
                background-color: white;
            }

        .text-about {
            width: 50%;
            color: var(--white-yoyo);
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

    .ui-footer {
        grid-column: 3 / span 1;
        display: flex;
        flex-direction: column;
    }

        .ui-footer p {
            color: var(--white-yoyo);
        }

        .ui-footer #ui-button-logout {
            cursor: pointer;
        }

    .contact {
        grid-column: 4 / span 1;
        display: flex;
        flex-direction: column;
        color: var(--white-yoyo);
        justify-content: space-between;
    }

        .city-telephone-links-contact {
            order: 2;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

            #city-contact {
                display: none;
            }

        .logo-copyright-contact {
            order: 3;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: flex-end;
        }

            #contact-logo {
                order: 2;
                width: 30%;
            }

            .copyright {
                order: 1;
                width: 70%;
                font-size: 1.25rem;
                display: flex;
                flex-direction: row;
                gap: 0.25rem;
                color: var(--white-yoyo);

            }

            #contact-logo .st0{fill:var(--red-yoyo);}
            #contact-logo .st1{fill:var(--white-yoyo);}

        .email-contact {
            order: 1;
        }
}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

    .about {
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
            .slider-photos-carousel {
                width: 50%;
                filter: grayscale(100%);
                mix-blend-mode: screen;
                background-color: white;
            }

        .text-about {
            width: 50%;
            color: var(--white-yoyo);
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

    .ui-footer {
        grid-column: 3 / span 1;
        display: flex;
        flex-direction: column;
    }

        .ui-footer p {
            color: var(--white-yoyo);
        }

        .ui-footer #ui-button-logout {
            cursor: pointer;
        }

    .contact {
        grid-column: 4 / span 1;
        display: flex;
        flex-direction: column;
        color: var(--white-yoyo);
        justify-content: space-between;
    }

        .city-telephone-links-contact {
            order: 2;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

            #city-contact {
                display: none;
            }

        .logo-copyright-contact {
            order: 3;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: flex-end;
        }

            #contact-logo {
                order: 2;
                width: 30%;
            }

            .copyright {
                order: 1;
                width: 70%;
                font-size: 1rem;
                display: flex;
                flex-direction: row;
                gap: 0.25rem;
                color: var(--white-yoyo);

            }

            #contact-logo .st0{fill:var(--red-yoyo);}
            #contact-logo .st1{fill:var(--white-yoyo);}

        .email-contact {
            order: 1;
        }
}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .about {
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }
            .slider-photos-carousel {
                width: 50%;
                filter: grayscale(100%);
                mix-blend-mode: screen;
                background-color: white;
            }

        .text-about {
            width: 50%;
            color: var(--white-yoyo);
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

    .ui-footer {
        display: none;
    }

    .contact {
        grid-column: 3 / span 1;
        display: flex;
        flex-direction: column;
        color: var(--white-yoyo);
        justify-content: space-between;
    }

        .city-telephone-links-contact {
            order: 2;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

            #city-contact {
                display: none;
            }

        .logo-copyright-contact {
            order: 3;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: flex-end;
        }

            #contact-logo {
                order: 2;
                width: 30%;
            }

            .copyright {
                order: 1;
                width: 70%;
                font-size: 0.75rem;
                display: flex;
                flex-direction: row;
                gap: 0.25rem;
                color: var(--white-yoyo);

            }

            #contact-logo .st0{fill:var(--red-yoyo);}
            #contact-logo .st1{fill:var(--white-yoyo);}

        .email-contact {
            order: 1;
        }
}

/* MOBILE APPLICATION */

@media (max-width: 728px) {
    
    .about {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

        .text-about {
            order: 1;
            gap: 1rem;
            display: flex;
            flex-direction: column;
        }

            .text-about p {
                color: var(--white-yoyo);
            }

        .slider-photos-carousel {
            order: 2;
            height: 100vw;
            width: 100%;
            filter: grayscale(100%);
            mix-blend-mode: screen;
            background-color: white;
        }

    .ui-footer {
        display: none;
    }

    .contact {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: var(--white-yoyo);
        justify-content: space-between;
    }

        .city-telephone-links-contact {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .logo-copyright-contact {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            order: 3;
            align-items: flex-end;
        }

            #contact-logo {
                order: 2;
                width: 50%;
            }

            .copyright {
                order: 1;
                font-size: 0.75rem;
                display: flex;
                flex-direction: row;
                gap: 0.25rem;
                color: var(--white-yoyo);
                width: 50%;
            }

            #contact-logo .st0{fill:var(--red-yoyo);}
            #contact-logo .st1{fill:var(--white-yoyo);}
}
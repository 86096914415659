/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .projects-list {
        height: 100%;
        display: grid;
        overflow-y: scroll;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        align-items: flex-start;
        align-content: start;
    }
    
        .project-list-element {
            position: relative;
        }
    
            .img-project-thumbnail {
                transition: opacity 0.4s ease-in-out;
                width: 100%;
            }
    
            .text-project-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
                color: var(--dark-yoyo);
            }

                .text-project-thumbnail p:last-child {
                    text-align: right;
                }
    
            .project-list-element:hover .img-project-thumbnail {
                opacity: 0;
            }
            
            .project-list-element:hover .text-project-thumbnail {
                opacity: 1;
            }

}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

    .projects-list {
        height: 100%;
        display: grid;
        overflow-y: scroll;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        align-items: flex-start;
        align-content: start;
    }
    
        .project-list-element {
            position: relative;
        }
    
            .img-project-thumbnail {
                transition: opacity 0.4s ease-in-out;
                width: 100%;
            }
    
            .text-project-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
                color: var(--dark-yoyo);
            }

                .text-project-thumbnail p:last-child {
                    text-align: right;
                }
    
            .project-list-element:hover .img-project-thumbnail {
                opacity: 0;
            }
            
            .project-list-element:hover .text-project-thumbnail {
                opacity: 1;
            }

}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .projects-list {
        height: 100%;
        display: grid;
        overflow-y: scroll;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        align-items: flex-start;
        align-content: start;
    }
    
        .project-list-element {
            position: relative;
        }
    
            .img-project-thumbnail {
                transition: opacity 0.4s ease-in-out;
                width: 100%;
            }
    
            .text-project-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
                color: var(--dark-yoyo);
            }

                .text-project-thumbnail p:last-child {
                    text-align: right;
                }
    
            .project-list-element:hover .img-project-thumbnail {
                opacity: 0;
            }
            
            .project-list-element:hover .text-project-thumbnail {
                opacity: 1;
            }

}

/* MOBILE APPLICATION */

@media (max-width: 728px) {

    .projects-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    
        .project-list-element {
        }

            .project-thumbnail {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

                .img-project-thumbnail {
                    width: 100%;
                    order: 2;
                }
        
                .text-project-thumbnail {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    color: var(--dark-yoyo);
                    order: 1;
                    padding: 0 1rem;
                }

    .ui-create-project {
        display: none;
    }
}
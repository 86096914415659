button {
    all: unset;
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

    #red-button {
        background-color: var(--red-yoyo);
        color: var(--white-yoyo);
        border: solid thin var(--red-yoyo);
    }

    #white-button {
        background-color: var(--white-yoyo);
        color: var(--red-yoyo);
        border: solid thin var(--red-yoyo);
    }

    #no-box-button {
        padding: 0;
        gap: 0.25rem;
    }
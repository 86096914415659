.st0{fill:var(--white-yoyo)}
.st1{fill:var(--red-yoyo)}

/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .logo-container {
        padding-bottom: 0.25rem;
    }

        #presentation-logo .st0{fill:var(--white-yoyo)}
        #presentation-logo .st1{fill:var(--red-yoyo)}

    .presentation-text {
        flex-grow: 1;
        color: var(--red-yoyo);
    }
    
        .presentation-text p {
            text-align: center;
        }

    .city-country-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--red-yoyo);
    }
    
        .city-country-name p:first-child {
            text-align: left;
        }
    
        .city-country-name p:nth-child(2){
            text-align: right;
        }

}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

    .logo-container {
        padding-bottom: 0.25rem;
    }

        #presentation-logo .st0{fill:var(--white-yoyo)}
        #presentation-logo .st1{fill:var(--red-yoyo)}

    .presentation-text {
        flex-grow: 1;
        color: var(--red-yoyo);
    }
    
        .presentation-text p {
            text-align: center;
        }

    .city-country-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--red-yoyo);
    }
    
        .city-country-name p:first-child {
            text-align: left;
        }
    
        .city-country-name p:nth-child(2){
            text-align: right;
        }

}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .logo-container {
        padding-bottom: 0.25rem;
    }

        #presentation-logo .st0{fill:var(--white-yoyo)}
        #presentation-logo .st1{fill:var(--red-yoyo)}

    .presentation-text {
        flex-grow: 1;
        color: var(--red-yoyo);
    }
    
        .presentation-text p {
            text-align: center;
        }

    .city-country-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--red-yoyo);
    }
    
        .city-country-name p:first-child {
            text-align: left;
        }
    
        .city-country-name p:nth-child(2){
            text-align: right;
        }

}

/* MOBILE APPLICATION */

@media (max-width: 728px) {

        #presentation-logo .st0{fill:var(--white-yoyo)}
        #presentation-logo .st1{fill:var(--red-yoyo)}

    .presentation-text {
        color: var(--red-yoyo);
    }
    
        .presentation-text p {
            text-align: center;
        }

    .city-country-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--red-yoyo);
    }
    
        .city-country-name p:first-child {
            text-align: left;
        }
    
        .city-country-name p:nth-child(2){
            text-align: right;
        }

}
.loading {
  width: 100%;
  height: 1rem;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: var(--red-yoyo);
  animation: slideRight 1s linear forwards;
}

@keyframes slideRight {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

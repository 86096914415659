* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white-yoyo: #FFFFFF;
    --red-yoyo: #DE4C00;
    --dark-yoyo: #212427;
    --accent-color: #0B76DE;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--dark-yoyo);
    background-color: var(--white-yoyo);
    text-transform: uppercase;
    font-weight: 500;
}

a {
    text-decoration: none;
}

ul {
    list-style-type: none;
}

.accent-color {
    color: var(--red-yoyo)
}

.accent-text {
    color: var(--red-yoyo);
    font-style: italic;
}

/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {
    :root {
        font-size: 12px;
    }
}

/* TEXT SIZE DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {
    :root {
        font-size: 9px;
    }
}

/* TEXT SIZE TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {
    :root {
        font-size: 12px;
    }
}

/* TEXT SIZE MOBILE APPLICATION */

@media (max-width: 728px) {
    :root {
        font-size: 12px;
    }
}
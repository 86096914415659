/* LARGE DESKTOP APPLICATION */

@media (min-width: 1601px) {

    .project-header {
        grid-area: header;
    }
    
        .top-exit-button {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
        }
    
            .top-exit-button p {
                color: var(--red-yoyo)
            }
    
        .header-data {
            font-size: 4.25rem;
            font-weight: 400;
            line-height: 4rem;
            margin-top: 0.5rem;
        }
    
    .project-artcover {
        grid-area: artcover;
        width: 100%;
        overflow: hidden;
    }
    
        .project-artcover img {
            max-width: 100%;
            display: block;
        }
    
    .project-description {
        grid-area: desc;
        width: 100%;
        height: 100%;
    }
    
    .ui-project {
        grid-column: uiproj;
        display: flex;
        flex-direction: column;
    }
    
        .ui-project a {
            color: var(--red-yoyo);
        }
    
    .project-insert {
        grid-area: insert;
        width: 100%;
    }
    
    .project-genre {
        grid-area: genre;
        width: 100%;
    }
    
        .project-genre h2 {
            font-size: 4.25rem;
            font-weight: 400;
            line-height: 4rem;
            color: var(--red-yoyo)
        }
    
    .bottom-exit-button {
        display: none;
    }
    
}

/* DESKTOP APPLICATION */

@media (min-width: 1025px) and (max-width: 1600px) {

.project-header {
    grid-area: header;
}

    .top-exit-button {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
    }

        .top-exit-button p {
            color: var(--red-yoyo)
        }

    .header-data {
        font-size: 4rem;
        font-weight: 400;
        line-height: 3.75rem;
        margin-top: 0.5rem;
    }

.project-artcover {
    grid-area: artcover;
    width: 100%;
    overflow: hidden;
}

    .project-artcover img {
        max-width: 100%;
        display: block;
    }

.project-description {
    grid-area: desc;
    width: 100%;
    height: 100%;
}

.ui-project {
    grid-column: uiproj;
    display: flex;
    flex-direction: column;
}

    .ui-project a {
        color: var(--red-yoyo);
    }

.project-insert {
    grid-area: insert;
    width: 100%;
}

.project-genre {
    grid-area: genre;
    width: 100%;
}

    .project-genre h2 {
        font-size: 4rem;
        font-weight: 400;
        line-height: 3.75rem;
        color: var(--red-yoyo)
    }

.bottom-exit-button {
    display: none;
}

}

/* TABLET APPLICATION */

@media (min-width: 729px) and (max-width: 1024px) {

    .project-header {
        grid-area: header;
    }
    
        .top-exit-button {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
        }
    
            .top-exit-button p {
                color: var(--red-yoyo)
            }
    
        .header-data {
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 2.25rem;
            margin-top: 0.5rem;
        }
    
    .project-artcover {
        grid-area: artcover;
        width: 100%;
        overflow: hidden;
    }
    
        .project-artcover img {
            max-width: 100%;
            display: block;
        }
    
    .project-description {
        grid-area: desc;
        width: 100%;
        height: 100%;
    }
    
    .ui-project {
        display: none;
    }
    
    .project-insert {
        grid-area: insert;
        width: 100%;
    }
    
    .project-genre {
        grid-area: genre;
        width: 100%;
    }
    
        .project-genre h2 {
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 2.25rem;
            color: var(--red-yoyo)
        }
    
    .bottom-exit-button {
        display: none;
    }
    
}

/* MOBILE APPLICATION */

@media (max-width: 728px) {

.project-header {
    grid-area: header;
    padding: 0 1rem;
}

    .top-exit-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 1rem 0;
    }

        .top-exit-button p {
            color: var(--red-yoyo);
        }

    .header-data {
        font-size: 3.5rem;
        font-weight: 400;
        line-height: 3.25rem;
    }

.project-artcover {
    grid-area: artcover;
    width: 100%;
    overflow: hidden;
}

    .project-artcover img {
        max-width: 100%;
        display: block;
    }

.project-description {
    grid-area: desc;
    width: 100%;
    padding: 0 1rem;
    margin: 1rem 0;
}

.project-insert {
    grid-area: insert;
    width: 100%;
    padding: 0 1rem;
}

.project-genre {
    grid-area: genre;
    width: 100%;
}

    .project-genre h2 {
        font-size: 3.5rem;
        font-weight: 400;
        line-height: 3.25rem;
        color: var(--red-yoyo)
    }

.bottom-exit-button {
    grid-area: exit;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

    .bottom-exit-button p {
        color: var(--red-yoyo);
        text-align: right;
    }

.ui-project {
    display: none;
}

}